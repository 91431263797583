import React from 'react';
import { Badge } from 'antd';
import { Link } from 'react-router-dom';
import {
  HomeOutlined,
  ClusterOutlined,
  SettingOutlined,
  LoadingOutlined,
  AlertOutlined,
  FileSearchOutlined,
  ReconciliationOutlined,
  FundViewOutlined,
  TableOutlined,
  BarChartOutlined,
  LineChartOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { GetDecryptedString } from './encryption';

//system ID
export const systemID = '003';

//時間區間
export const DEFAULT_TIMEZONE = '8';

//刷新時間
export const refetchInterval = 1000 * 60 * 5;

// loading spin
export const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 55,
    }}
    spin
  />
);

// menu
export const menu = (obj) => {
  const arr = [
    {
      key: '/',
      icon: <HomeOutlined />,
      label: <Link to="/">首頁/儀表板</Link>,
      name: '首頁/儀表板',
      path: '/',
    },
    {
      key: '/device',
      icon: <ClusterOutlined />,
      label: <Link to="/device">能源設備總覽</Link>,
      name: '能源設備總覽',
      path: '/device',
    },
    {
      key: '/analysis',
      icon: <FileSearchOutlined />,
      label: <Link to="/analysis">分析報告</Link>,
      name: '分析報告',
      path: '/analysis',
    },
    {
      key: '/contractconfig',
      icon: <ReconciliationOutlined />,
      label: <Link to="/contractconfig">契約容量管理</Link>,
      name: '契約容量管理',
      path: '/contractconfig',
    },
    {
      key: '/alert',
      icon: (
        <Badge count={obj?.alert || null} size="small">
          <AlertOutlined />
        </Badge>
      ),
      label: (
        <>
          <Link to="/alert">
            <Badge count={obj?.alert || null} size="small" offset={[16, 7]}>
              警告列表
            </Badge>
          </Link>
        </>
      ),
      name: '警告列表',
      path: '/alert',
    },
    {
      key: '/settings',
      icon: <SettingOutlined />,
      label: <Link to="/settings">環境設定</Link>,
      name: '環境設定',
      path: '/settings',
    },
  ];
  if (obj?.customTimeLineMode)
    arr.splice(1, 0, {
      key: '/timelinedashboard',
      icon: <PlayCircleOutlined />,
      label: <Link to="/timelinedashboard">模擬能源調度</Link>,
      name: '模擬能源調度',
      path: '/timelinedashboard',
    });
  return arr;
};

//路由
export const routeList = [
  {
    path: '/',
    breadcrumbName: (
      <>
        <HomeOutlined />
        <span>微電能源</span>
      </>
    ),
  },
  {
    path: '/filemanager',
    breadcrumbName: '檔案瀏覽',
  },
  {
    path: '/filemanager/folders',
    breadcrumbName: '資料夾',
  },
  {
    path: '/filemanager/folders/:id',
    breadcrumbName: '資料夾',
  },
  {
    path: '/projects',
    breadcrumbName: '專案資訊管理',
  },
  {
    path: '/settings',
    breadcrumbName: '系統設定',
  },
  {
    path: '/changepassword',
    breadcrumbName: '重設密碼',
  },
];

//抓取token
export const getToken = () => {
  return GetDecryptedString(window.sessionStorage.getItem('token'));
};

//抓取refreshToken
export const getRefreshToken = () => {
  return GetDecryptedString(window.sessionStorage.getItem('refreshToken'));
};

//麵包屑計算路由
export const itemRender = (route, params, routes) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? <span>{route.breadcrumbName}</span> : <Link to={route.path}>{route.breadcrumbName}</Link>;
};

export const getTimelinePeriod = (timeline = 'day') => {
  if (timeline === 'day' || timeline === 'week') {
    return 'quarter';
  } else if (timeline === 'month') {
    return 'daily';
  } else if (timeline === 'year') {
    return 'monthly';
  } else {
    return 'yearly';
  }
};

export const getTimelinePeriodForTarget = (timeline = 'day') => {
  if (timeline === 'day' || timeline === 'week') {
    return 'daily';
  } else if (timeline === 'month') {
    return 'monthly';
  } else if (timeline === 'year') {
    return 'yearly';
  } else {
    return 'yearly';
  }
};

export const dateOptions = [
  {
    label: '天',
    value: 'day',
  },
  {
    label: '週',
    value: 'week',
  },
  {
    label: '月',
    value: 'month',
  },
  {
    label: '年',
    value: 'year',
  },
  {
    label: '累積至今',
    value: 'accumulatedToNow',
  },
];

//計算累積值
export const calculateCumulationValue = (data = [], key = 'value') => {
  if (data?.length === 0 || data === null) return 0;
  const cumulationValue = data?.reduce?.(
    (accumulator, currentValue) => accumulator + (currentValue?.[key] || 0),
    0
  );
  return Number(cumulationValue?.toFixed(2));
};

export const defaultAnalysisMenu = [
  {
    key: 'energyFlow',
    label: '能源流動',
    type: 'group',
    children: [
      {
        key: 'energyControl',
        icon: <FundViewOutlined />,
        label: '能源調度',
      },
      {
        key: 'energyReview',
        icon: <TableOutlined />,
        label: '能源使用總覽',
      },
      {
        key: 'contractStatus',
        icon: <TableOutlined />,
        label: '契約容量狀態',
      },
      {
        key: 'energyTarget',
        icon: <TableOutlined />,
        label: '能源調度指標',
      },
      {
        key: 'carbonEmissions',
        icon: <BarChartOutlined />,
        label: '減少的碳排放量',
      },
    ],
  },
  {
    key: 'energyDevice',
    label: '能源設備',
    type: 'group',
    children: [
      {
        key: 'solarMonitor',
        icon: <LineChartOutlined />,
        label: '太陽能設備',
      },
      {
        key: 'loadMonitor',
        icon: <LineChartOutlined />,
        label: '負載設備',
      },
      {
        key: 'essMonitor',
        icon: <LineChartOutlined />,
        label: '儲能設備',
      },
      {
        key: 'evseMonitor',
        icon: <LineChartOutlined />,
        label: '充電樁設備',
        name: '充電樁設備',
      },
      {
        key: 'windMonitor',
        icon: <LineChartOutlined />,
        label: '風能設備',
      },
    ],
  },
  {
    key: 'economyTarget',
    label: '經濟指標',
    type: 'group',
    children: [
      {
        key: 'greenBenefits',
        icon: <BarChartOutlined />,
        label: '綠電節電效益',
      },
      {
        key: 'essBenefits',
        icon: <BarChartOutlined />,
        label: '儲能節電效益',
      },
      {
        key: 'economyEstimate',
        icon: <BarChartOutlined />,
        label: '經濟效益預估',
      },
    ],
  },
];

//token過期時間 (30分)
export const expiresIn = 30 * 60 * 1000;

//編碼網址參數
export const codeParams = (data) => {
  let queryString = '';

  // 遍历数组，为每个对象构建查询字符串
  data.forEach((obj, index) => {
    Object.keys(obj).forEach((key) => {
      queryString += `conditions[${index}][${key}]=${encodeURIComponent(obj[key])}&`;
    });

    return queryString;
  });

  // 移除最后一个多余的"&"
  return queryString.slice(0, -1);
};

//解析網址參數
export const decodeParams = (queryString) => {
  // 解析查询字符串
  const params = new URLSearchParams(queryString);

  // 初始化结果数组
  const result = [];

  // 遍历所有参数
  for (const [key, value] of params) {
    // 使用正则表达式匹配索引和字段名
    const match = key.match(/conditions\[(\d+)\]\[(\w+)\]/);
    if (match) {
      const index = parseInt(match[1], 10);
      const field = match[2];

      // 确保数组长度
      while (index >= result.length) {
        result.push({});
      }

      // 添加值到对应的对象中
      result[index][field] = value;
    }
  }
  return result;
};

//清除使用者資訊
export const sessionClear = () => {
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('token');
};
