import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Statistic, notification } from 'antd';
import App from './App';
import { AuthContextProvider } from './contexts/authProvider';
import { SettingContextProvider } from './contexts/settingProvider';
import axios from 'axios';
import { sessionClear } from './utils/functionTool';
import 'antd/dist/reset.css';
import './css/main.css';
import './css/dark.css';
import './css/rwd.css';

const { Countdown } = Statistic;

const logout = () => {
  notification.warning({
    message: (
      <div>
        登入逾時，系統將於
        <Countdown
          value={Date.now() + 3 * 1000}
          format="s"
          onFinish={() => (window.location.href = '/login')}
          style={{ display: 'inline-block', padding: '0 2px' }}
          className="logout-count-down"
        />
        秒後自動登出，請再次登入
      </div>
    ),
    placement: 'top',
    key: '401',
  });
  sessionClear();
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          const detail = error?.response?.data?.detail;
          if (detail?.includes('pageToken')) {
            console.warn('pageToken error');
          } else if (detail?.includes('Not Authenticated!')) {
            console.warn('Not Authenticated!');
            logout();
          } else {
            console.warn('Google Drive API error');
            notification.warning({
              message: <div>無效的請求或無權限</div>,
              placement: 'top',
              key: '400',
            });
          }
          break;
        case 401:
          console.warn('端點請求超時或無權限');
          logout();
          break;
        case 403:
          console.warn('沒有存取權限');
          notification.warning({
            message: <div>沒有存取權限，無權限使用此動作，如有疑問請通知系統管理員確認權限</div>,
            placement: 'top',
            key: '403',
          });
          break;
        case 404:
          console.warn('找不到該頁面');
          notification.warning({
            message: <div>找不到該頁面</div>,
            placement: 'top',
            key: '403',
          });
          break;
        case 500:
          const errorDetails = error.response.data.detail || '';
          const isPageTokenError = errorDetails?.includes('pageToken');
          if (isPageTokenError) {
            console.warn('pageToken error');
          } else {
            console.warn('伺服器出錯');
            notification.warning({
              message: <div>伺服器出錯</div>,
              placement: 'top',
              key: '500',
            });
          }
          break;
        case 503:
          console.warn('服務失效');
          notification.warning({
            message: <div>服務失效</div>,
            placement: 'top',
            key: '503',
          });
          break;
        case 504:
          console.warn('伺服器逾時');
          notification.warning({
            message: <div>伺服器逾時，請重新嘗試</div>,
            placement: 'top',
            key: '504',
          });
          break;
        default:
          console.warn(`連接錯誤: ${error.response.status}`);
      }
    }
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <SettingContextProvider>
        <App />
      </SettingContextProvider>
    </AuthContextProvider>
  </QueryClientProvider>
  //</React.StrictMode>
);
