import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

function createQueryField(field, operation, value) {
  return { field, operation, value };
}

function useQueryParams() {
  const { search, pathname } = useLocation();

  return useMemo(() => {
    // 如果在 filemanager 根目錄路由
    if (pathname === '/filemanager' && search === '') {
      const sharedDrivesField = createQueryField('shared_drives', 'system', true);
      const query = [sharedDrivesField];
      return query;
    }

    // 如果在資料夾路由
    if (pathname.includes('folders')) {
      const segments = pathname.split('/');
      const searchParams = new URLSearchParams(search);
      const root = searchParams.get('root') === 'true'; // 将 root 参数转为布尔值

      if (segments.length >= 4) {
        const parentsField = createQueryField('parents', 'in', segments[3]);
        const query = [parentsField];

        // 最高權限
        if (root) {
          query.push(createQueryField('root', 'system', true));
        }

        // 不在垃圾桶
        query.push(createQueryField('trashed', 'boolean', false));

        return query;
      }
    }

    // 參數路由
    const parseQueryParams = (queryString) => {
      const params = new URLSearchParams(queryString);
      const result = [];

      for (const [key, value] of params) {
        const match = key.match(/conditions\[(\d+)\]\[(\w+)\]/);
        if (match) {
          const index = parseInt(match[1], 10);
          const field = match[2];
          let parsedValue = value;

          // 將 "true" 和 "false" 字符串轉換為布爾值
          if (value === 'true') {
            parsedValue = true;
          } else if (value === 'false') {
            parsedValue = false;
          }

          while (index >= result.length) {
            result.push({});
          }

          result[index][field] = parsedValue;
        }
      }

      return result;
    };

    return parseQueryParams(search);
  }, [search, pathname]); // 只有當 search 或 pathname 變化時，才重新計算
}

export default useQueryParams;
