import axios from 'axios';
import { API_BASE_URL, WS_BASE_URL } from '../utils/config';
import { GetDecryptedString, decodeJWT } from '../utils/encryption'; // 新增導入

const BASE_URL = `${API_BASE_URL}/ctrag`;
const GOOGLE_API_BASE_URL = `${API_BASE_URL}/google`;
const DRIVE_API_BASE_URL = `${GOOGLE_API_BASE_URL}/drive`;

export const checkChecksumsExist = async (checksums) => {
  try {
    const response = await axios.post(`${BASE_URL}/check_checksums_exist`, { checksums });
    return response.data;
  } catch (error) {
    console.error('檢查校驗和失敗:', error);
    throw error;
  }
};

export const dispatch = async (dispatchPayload) => {
  try {
    const response = await axios.post(`${BASE_URL}/dispatch`, {
      collection: dispatchPayload.collection,
      retrieval: dispatchPayload.retrieval,
      transformation: dispatchPayload.transformation,
      augmentation: dispatchPayload.augmentation,
      generation: dispatchPayload?.generation,
    });
    return response.data;
  } catch (error) {
    console.error('分發任務失敗:', error);
    throw error;
  }
};

// Update the chat function to handle streaming
export const chat = async (chatPayload) => {
  const token = GetDecryptedString(window.localStorage.getItem('token'));
  try {
    const response = await fetch(`${BASE_URL}/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(chatPayload),
    });

    if (!response.ok) {
      throw new Error('Chat request failed');
    }

    // Return the response object directly for streaming
    return response;
  } catch (error) {
    console.error('Chat request failed:', error);
    throw error;
  }
};

// WebSocket chat function
export const wsChat = () => {
  const token = GetDecryptedString(window.localStorage.getItem('token'));
  let ws = null;
  let messageCallback = null;
  let errorCallback = null;
  let isConnected = false;
  let reconnectAttempts = 0;
  const maxReconnectAttempts = 3;
  let messageQueue = [];
  let connectPromise = null;

  const connect = () => {
    if (connectPromise) return connectPromise;

    connectPromise = new Promise((resolve, reject) => {
      try {
        ws = new WebSocket(`${WS_BASE_URL}`);
        
        ws.onopen = () => {
          console.debug('WebSocket 連線已建立');
          isConnected = true;
          reconnectAttempts = 0;
          // 初始化連線時傳送 token
          ws.send(JSON.stringify({ token }));
          resolve(ws);
          
          // 處理排隊的消息
          while (messageQueue.length > 0) {
            const { message, resolve: queuedResolve } = messageQueue.shift();
            try {
              ws.send(JSON.stringify(message));
              queuedResolve();
            } catch (error) {
              console.error('Failed to send queued message:', error);
            }
          }
        };

        ws.onclose = (event) => {
          console.debug('WebSocket connection closed:', event);
          isConnected = false;
          connectPromise = null;
          
          if (event.code !== 1000) { // 非正常關閉才重連
            // 嘗試重新連接
            if (reconnectAttempts < maxReconnectAttempts) {
              reconnectAttempts++;
              setTimeout(() => {
                console.debug(`Attempting to reconnect (${reconnectAttempts}/${maxReconnectAttempts})`);
                connect();
              }, 1000 * Math.pow(2, reconnectAttempts)); // 指數退避
            }
          }
        };

        ws.onerror = (error) => {
          console.error('WebSocket error:', error);
          isConnected = false;
          if (errorCallback) {
            errorCallback(error);
          }
          reject(error);
        };

        ws.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            if (messageCallback) {
              messageCallback(data);
            }
          } catch (error) {
            console.error('Error parsing WebSocket message:', error);
            if (errorCallback) {
              errorCallback(new Error('Failed to parse message'));
            }
          }
        };

      } catch (error) {
        console.error('WebSocket connection error:', error);
        connectPromise = null;
        reject(error);
      }
    });

    return connectPromise;
  };

  // 初始連接
  connect();

  return {
    // 發送消息
    sendMessage: async (chatPayload) => {
      // 如果沒有連接，先嘗試連接
      if (!isConnected || !ws || ws.readyState !== WebSocket.OPEN) {
        return new Promise((resolve, reject) => {
          messageQueue.push({
            message: chatPayload,
            resolve,
            reject
          });
          
          // 嘗試重新連接
          connect().catch(reject);
        });
      }

      // 如果已經連接，直接發送
      return new Promise((resolve, reject) => {
        try {
          ws.send(JSON.stringify(chatPayload));
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    // 設置消息處理回調
    onMessage: (callback) => {
      messageCallback = callback;
    },

    // 設置錯誤處理回調
    onError: (callback) => {
      errorCallback = callback;
    },

    // 關閉連接
    close: () => {
      if (ws) {
        isConnected = false;
        ws.close(1000, "Normal closure"); // 使用正常關閉代碼
      }
    },

    // 檢查連接狀態
    isConnected: () => {
      return isConnected && ws && ws.readyState === WebSocket.OPEN;
    },

    // 重新連接
    reconnect: () => {
      if (ws) {
        ws.close();
      }
      return connect();
    }
  };
};




export const getRelatedQuestions = async (chatSessionId) => {
  try {
    const token = GetDecryptedString(window.localStorage.getItem('token'));
    const response = await axios.get(`${BASE_URL}/get_related_questions/${chatSessionId}`, {
      headers: {
        'Authorization': token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('獲取相關問題失敗:', error);
    throw error;
  }
};


export const getEventDetails = async (eventId) => {
  try {
    const response = await axios.get(`${BASE_URL}/event/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('獲取事件詳情失敗:', error);
    throw error;
  }
};

export const getEventStatus = async (eventId) => {
  try {
    const response = await axios.get(`${BASE_URL}/event_status/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('獲取事件狀態失敗:', error);
    throw error;
  }
};

export const getEventStatusDetail = async (eventId) => {
  try {
    const response = await axios.get(`${BASE_URL}/event_status_detail/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('獲取事件狀態詳情失敗:', error);
    throw error;
  }
};

export const getResult = async (eventId) => {
  try {
    const response = await axios.get(`${BASE_URL}/result/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('獲取結果失敗:', error);
    throw error;
  }
};

export const getResultDetail = async (eventId) => {
  try {
    const response = await axios.get(`${BASE_URL}/result_detail/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('獲取詳細結果失敗:', error);
    throw error;
  }
};

export const embedding = async (embeddingRequest) => {
  try {
    const response = await axios.post(`${BASE_URL}/embedding`, embeddingRequest);
    return response.data;
  } catch (error) {
    console.error('嵌入請求失敗:', error);
    throw error;
  }
};

// 新增獲取使用者聊天會話列表的函數
export const getChatSessionsByUserEmail = async (page = 1, page_size = 10) => {
  const token = GetDecryptedString(window.localStorage.getItem('token')); // 獲取並解密 token
  try {
    const response = await axios.get(`${BASE_URL}/chat_session`, {
      headers: {
        'Authorization': token, // 加入 Authorization 標頭
      },
      params: {
        page: page,        // 新增 page 參數
        pageSize: page_size,   // 新增 page_size 參數
      },
    });
    return response.data;
  } catch (error) {
    console.error('獲取聊天會話列表失敗:', error);
    throw error;
  }
};

// 修改 getChatSessionHistoryById 函數，加入 page 和 page_size 參數
export const getChatSessionHistoryById = async (chatSessionId, contextWindowLength, page = 1, page_size = 10) => {
  try {
    const response = await axios.get(`${BASE_URL}/chat_session_history/${chatSessionId}`, {
      headers: {
        'Authorization': GetDecryptedString(window.localStorage.getItem('token')),
      },
      params: {
        context_window_length: contextWindowLength,
        page: page,          // 新增 page 參數
        pageSize: page_size // 新增 page_size 參數
      },
    });
    return response.data.chat_histories;
  } catch (error) {
    console.error('獲取聊天歷史記錄失敗:', error);
    throw error;
  }
};

// 新增知識庫
export const createLLMKnowledgeBase = async (data) => {
  const token = GetDecryptedString(window.localStorage.getItem('token'));
  try {
    const response = await axios.post(`${BASE_URL}/llm_knowledge_base`, data, {
      headers: {
        'Authorization': token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('新增知識庫失敗:', error);
    throw error;
  }
};

// 獲取知識庫
export const getLLMKnowledgeBase = async (knowledgeBaseName = null) => {
  const token = GetDecryptedString(window.localStorage.getItem('token'));
  try {
    const response = await axios.get(`${BASE_URL}/llm_knowledge_base`, {
      headers: {
        'Authorization': token,
      },
      params: knowledgeBaseName,
    });
    return response.data;
  } catch (error) {
    console.error('獲取知識庫失敗:', error);
    throw error;
  }
};

// 更新或插入知識庫
export const upsertLLMKnowledgeBase = async (data) => {
  const token = GetDecryptedString(window.localStorage.getItem('token'));
  try {
    const response = await axios.put(`${BASE_URL}/llm_knowledge_base`, data, {
      headers: {
        'Authorization': token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('更新或插入知識庫失敗:', error);
    throw error;
  }
};

// 刪除知識庫
export const removeLLMKnowledgeBase = async (knowledgeBaseName) => {
  const token = GetDecryptedString(window.localStorage.getItem('token'));
  try {
    const response = await axios.delete(`${BASE_URL}/llm_knowledge_base`, {
      headers: {
        'Authorization': token,
      },
      params: { knowledge_base_name: knowledgeBaseName },
    });
    return response.data;
  } catch (error) {
    console.error('刪除知識庫失敗:', error);
    throw error;
  }
};

// 修改 getGoogleToken 函數
export const getGoogleToken = async () => {
  const token = GetDecryptedString(window.localStorage.getItem('token'));
  try {
    const response = await axios.get(`${GOOGLE_API_BASE_URL}/get-google-token`, {
      headers: {
        'Authorization': token,
      },
    });
    const shortTermToken = response.data.short_term_token;
    const decodedToken = decodeJWT(shortTermToken);
    if (!decodedToken) {
      throw new Error('無法解碼 JWT token');
    }
    return decodedToken.google_access_token;
  } catch (error) {
    console.error('獲取Google Token失敗:', error);
    if (error.response && error.response.status === 404) {
      throw new Error('找不到用戶的憑證。請先登錄。');
    } else {
      throw error;
    }
  }
};


// 獲取多個文件或文件夾
export const getFiles = async (fileIds) => {
  const token = GetDecryptedString(window.localStorage.getItem('token'));
  try {
    const response = await axios.post(`${DRIVE_API_BASE_URL}/files`, {
      file_ids: fileIds
    }, {
      headers: {
        'Authorization': token,
      }
    });
    return response.data;
  } catch (error) {
    console.error('獲取文件失敗:', error);
    throw error;
  }
};
// 獲取多個文件或文件夾的詳細信息
export const getDriveFiles = async (fileIds) => {
  const token = GetDecryptedString(window.localStorage.getItem('token'));
  try {
    const response = await axios.post(`${BASE_URL}/drive_files`, {
      file_ids: fileIds
    }, {
      headers: {
        'Authorization': token,
      }
    });

    // 檢查每個文件的狀態
    for (const file of response.data) {
      if (file.status === 3) {
        throw new Error(`已達到文件數量限制：文件 ID ${file.id}`);
      } else if (file.status === 2) {
        throw new Error(`獲取文件 '${file.name}' 失敗: ${file.message}`);
      }
    }

    return response.data;
  } catch (error) {
    console.error('獲取Google Drive文件詳細信息失敗:', error);
    if (error.response) {
      switch (error.response.status) {
        case 400:
          throw new Error('未提供文件ID');
        case 404:
          throw new Error('找不到指定的文件');
        default:
          throw new Error(`獲取文件失敗: ${error.response.data.detail || '未知錯誤'}`);
      }
    } else {
      throw new Error('網絡錯誤，請稍後再試');
    }
  }
};

