import { useState, useEffect, useCallback, useRef } from 'react'; // 新增 useRef
import { getChatSessionsByUserEmail } from '../api/ctrag'; // 新增導入

export const useChatHistory = (enabled = false) => { // 新增 `enabled` 參數
  const [chatHistory, setChatHistory] = useState([]);
  const [page, setPage] = useState(1); // 新增分頁狀態
  const [hasMore, setHasMore] = useState(true); // 新增是否有更多資料的狀態
  const [isLoading, setIsLoading] = useState(false);
  const isFetchedRef = useRef(false); // 使用 useRef 來追蹤是否已經獲取過聊天歷史
  
  const fetchChatHistory = useCallback(async () => {
    if (!enabled || isFetchedRef.current) return; // 檢查是否啟用及是否已經獲取過
    setIsLoading(true);
    try {
      const page_size = 10; // 設定每頁顯示 10 項
      const history = await getChatSessionsByUserEmail(1, page_size); // 傳遞 page 和 page_size
      setChatHistory(history);
      setPage(2);
      if (history.length < page_size) {
        setHasMore(false);
      }
      isFetchedRef.current = true; // 設置已獲取過
    } catch (error) {
      console.error('獲取聊天歷史記錄失敗:', error);
      isFetchedRef.current = false; // 若失敗，允許重試
    } finally {
      setIsLoading(false);
    }
  }, [enabled]);
  
  useEffect(() => {
    if (enabled) {
      fetchChatHistory();
    }
  }, [fetchChatHistory, enabled]);
  
  const loadMore = async () => { // 新增載入更多函數
    if (!hasMore || isLoading) return; // 防止重複載入
    setIsLoading(true);
    try {
      const page_size = 10; // 保持每頁顯示 10 項
      const history = await getChatSessionsByUserEmail(page, page_size); // 傳遞當前頁碼和 page_size
      if (history.length < page_size) {
        setHasMore(false);
      }
      setChatHistory(prev => [...prev, ...history]);
      setPage(prev => prev + 1);
    } catch (error) {
      console.error('載入更多聊天歷史記錄失敗:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const refreshChatHistory = useCallback(() => { // 新增刷新函數
    isFetchedRef.current = false;
    fetchChatHistory();
  }, [fetchChatHistory]);
  
  return { chatHistory, hasMore, isLoading, loadMore, refreshChatHistory }; // 返回刷新函數
};