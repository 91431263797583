import React, { useState, useEffect } from 'react'
import { Layout } from 'antd'

const { Footer } = Layout
const date = new Date()

let clickCount = 0; // 將 clickCount 移到函數外部

const AppFooter = (props) => {
    const [isDevMode, setIsDevMode] = useState(false);

    useEffect(() => {
        const devModeStatus = localStorage.getItem('devMode');
        setIsDevMode(devModeStatus === 'true');
    }, []);

    const handleMicroClick = () => {
        clickCount++;
        if (clickCount === 8) {
            const newDevMode = !isDevMode;
            setIsDevMode(newDevMode);
            localStorage.setItem('devMode', newDevMode.toString());
            clickCount = 0;
            alert(newDevMode ? '已開啟開發者模式' : '已關閉開發者模式');
        }
    };

    return (
        <Footer className="app-footer" {...props}>
            © {date.getFullYear()} <span onClick={handleMicroClick}>Micro</span> Electricity Co., Ltd. All rights
            reserved.
        </Footer>
    )
}

export default AppFooter
