import { AES, enc } from 'crypto-js';
import getFingerprint from './fingerprint';
import { IsNullOrUndefined } from './valid';
import CryptoJS from 'crypto-js';
import { jwtDecode } from 'jwt-decode'; // 修改這行

const secureKey = getFingerprint().toString();

export const GetEncryptedString = (val) => {
  if (IsNullOrUndefined(val)) return null;

  return AES.encrypt(val, secureKey).toString();
};

export const GetDecryptedString = (val) => {
  if (IsNullOrUndefined(val)) return null;

  try {
    return AES.decrypt(val, secureKey).toString(enc.Utf8);
  } catch (ex) {
    return null;
  }
};

export const GetEncryptObject = (obj) => {
  if (IsNullOrUndefined(obj)) return null;

  return AES.encrypt(JSON.stringify(obj), secureKey).toString();
};

export const GetDecryptedObject = (val) => {
  if (IsNullOrUndefined(val)) return null;

  try {
    const decryptedObject = AES.decrypt(val, secureKey).toString(enc.Utf8);
    return decryptedObject ? JSON.parse(decryptedObject) : null;
  } catch (ex) {
    return null;
  }
};

// 計算文件的 MD5 校驗和
export const calculateChecksum = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const binary = event.target.result;
      const md5 = CryptoJS.MD5(CryptoJS.lib.WordArray.create(binary));
      resolve(md5.toString());
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

// 新增 JWT 解碼函數
export const decodeJWT = (token) => {
  if (IsNullOrUndefined(token)) return null;

  try {
    return jwtDecode(token); // 使用 jwtDecode 而不是 jwt_decode
  } catch (ex) {
    console.error('JWT 解碼失敗:', ex);
    return null;
  }
};

